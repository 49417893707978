import { SUNMEDIA_GENERAL } from '@/views/private/modules/contextual/cluster/config';

export default class Cluster {
  /**
   *
   * @param {String} id
   * @param {String} name
   * @param {Object} segmentations
   * @param {String} sfOffice
   * @param {String} sfBrand
   * @param {String} sfAdvertiser
   * @param {Number} sfOppId
   * @param {String} sfName
   * @param {String} user
   */
  constructor(
    id = null,
    name = '',
    sfOffice = '',
    sfBrand = '',
    sfAdvertiser = '',
    sfOppId = null,
    sfName = '',
    weather = { tempMax: null, tempMin: null, rainMax: null, rainMin: null, pollutionMax: null, pollutionMin: null },
    segmentations = {},
    ownerClient = ''
  ) {
    this.id = id;
    this.name = name;
    this.sfOffice = sfOffice;
    this.sfBrand = sfBrand;
    this.sfAdvertiser = sfAdvertiser;
    this.sfOppId = sfOppId;
    this.sfName = sfName;
    this.weather = weather;
    this.segmentations = segmentations;
    this.ownerClient = ownerClient;
  }

  static create(entity) {
    const attr = entity.attributes;
    const ownerClient = attr.sfOppId === SUNMEDIA_GENERAL ? SUNMEDIA_GENERAL : `${attr.sfOffice} - ${attr.sfBrand}`;

    return new Cluster(
      attr.id,
      attr.name,
      attr.sfOffice,
      attr.sfBrand,
      attr.sfAdvertiser,
      attr.sfOppId,
      attr.sfName,
      attr.weather,
      attr.segmentations,
      ownerClient
    );
  }
}
