import { CONTEXTS } from '@/model/shared/contexts';
import { ClientShared } from '@/entities/shared/ClientShared';
import { Address } from '../shared/Address';

export class ContextualClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    address = new Address()
  ) {
    super(id, name, email, CONTEXTS.CONTEXTUAL.id, contactName, isExternal, users, taxId);
    this.address = address;
  }

  payload() {
    const { users, ...params } = this;
    const address = this.address?.payload?.() || this.address;
    return {
      ...params,
      address,
      userIds: users.map(user => user.id),
    };
  }

  /**
   *
   * @param {{id:string, attributes: ContextualClient}} entity
   * @param {Object} included
   * @return {ContextualClient}
   */
  static create(entity, included) {
    const linkedUsers = entity.relationships?.linkedUsers.data;
    const users = linkedUsers && included?.users?.filter(user => linkedUsers.find(linkUser => linkUser.id === user.id));

    return new ContextualClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      users,
      entity.attributes.taxId,
      Address.from(entity.attributes.address)
    );
  }
}
