import * as RESOURCE from '@/services/keys';
import { EntityParser } from '../shared/EntityParser';
import Cluster from './Cluster';
import { ContextualClient as Client } from './ContextualClient';
import { Opportunity } from '@/entities/shared/Opportunity';
import { AdvertiserShared } from '../shared/AdvertiserShared';

const contextualEntityParser = new EntityParser({
  [RESOURCE.CLIENT_RESOURCE]: Client.create,
  [RESOURCE.CLUSTER_RESOURCE]: Cluster.create,
  [RESOURCE.SALESFORCE_OPPORTUNITIES_RESOURCE]: Opportunity.create,
  [RESOURCE.SALESFORCE_ADVERTISERS_RESOURCE]: AdvertiserShared.create,
});

export default contextualEntityParser;
