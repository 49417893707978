import Column from '@/model/shared/Column';

export const COLUMNS = Object.freeze({
  NAME: new Column('CLUSTER NAME', 'name').asSort().setClass('font-bold truncate'),
  CLUSTER_ID: new Column('CLUSTER ID', 'id').asSort(),
  SEGMENTATIONS: new Column('SEGMENTATIONS', 'segmentations').asSort(true),
  OWNER_CLIENT: new Column('OWNER / CLIENT', 'ownerClient').asSort(),
  ACTIONS: new Column('ACTIONS', 'actions').setClass('w-4'),
});

export const segmentTypes = {
  EXCLUDE: 'EXCLUDE',
  INCLUDE: 'INCLUDE',
};

export const SUNMEDIA_GENERAL = 'Sunmedia General';

export const GROUP_TYPES = {
  CATEGORIES: 'Categories',
  ENTITIES: 'Entities',
  PUBLISHER: 'Publisher Type',
  SENSITIVE: 'Sensitive',
  PEOPLE: 'People',
  OBJECTS: 'Objects',
  PLACES: 'Places',
  LOGOS: 'Logos',
};

const defaultColumns = [COLUMNS.ACTIONS, COLUMNS.NAME, COLUMNS.CLUSTER_ID, COLUMNS.SEGMENTATIONS, COLUMNS.OWNER_CLIENT];

const transformColumnsForAgGrid = column => {
  const col = {};
  switch (column.value) {
    case COLUMNS.NAME.value:
    case COLUMNS.OWNER_CLIENT.value:
      col.maxWidth = 210;
      col.width = 120;
      col.minWidth = 120;
      col.type = 'textColumn';
      break;
    case COLUMNS.CLUSTER_ID.value:
      col.width = 120;
      col.minWidth = 120;
      col.maxWidth = 120;
      col.type = 'numberColumn';
      break;
    case COLUMNS.ACTIONS.value:
      col.width = 100;
      col.maxWidth = 120;
      col.suppressSizeToFit = true;
      col.suppressAutoSize = true;
      col.sortable = false;
      col.filter = false;
      col.resizable = false;
      col.pinned = 'left';
      col.cellRenderer = 'ActionContextualCell';
      break;
    case COLUMNS.SEGMENTATIONS.value:
      col.width = 120;
      col.minWidth = 120;
      col.type = 'objectColumn';
      break;
  }
  return { headerName: `${column.visibleTitle()}`, field: column.field || column.value, ...col };
};

export const CONFIG = Object.freeze({
  headers: defaultColumns.map(transformColumnsForAgGrid),
  columnTypes: {
    textColumn: {
      filter: 'agTextColumnFilter',
    },
    numberColumn: {
      filter: 'agNumberColumnFilter',
    },
    objectColumn: {
      filter: 'agTextColumnFilter',
    },
  },
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
    minWidth: 80,
    width: 150,
    cellRenderer: 'AnalyticsCellOrchestrator',
  },
  segmentationTabs: {
    contextual: [
      { title: GROUP_TYPES.CATEGORIES, id: 'categories' },
      { title: GROUP_TYPES.ENTITIES, id: 'entities' },
      { title: GROUP_TYPES.PUBLISHER, id: 'publisher' },
      { title: GROUP_TYPES.SENSITIVE, id: 'sensitive' },
    ],
    image: [
      { title: GROUP_TYPES.PEOPLE },
      { title: GROUP_TYPES.OBJECTS },
      { title: GROUP_TYPES.PLACES },
      { title: GROUP_TYPES.LOGOS },
    ],
    weather: [{ title: 'Temp' }, { title: 'Rain' }],
  },
  SUNMEDIA_GENERAL,
  GROUP_TYPES,
});
